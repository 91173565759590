import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 554.000000 554.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)">
          
          <path d="M1863 3670 c-89 -54 -83 -188 10 -231 36 -16 67 -19 219 -19 159 0
177 -2 182 -17 3 -10 41 -142 85 -293 44 -151 116 -400 160 -552 45 -153 81
-280 81 -284 0 -3 156 -4 348 -2 323 4 352 6 412 26 144 48 254 140 315 266
54 110 68 182 63 311 -8 177 -64 305 -180 410 -124 111 -210 135 -501 135
-238 0 -257 -5 -287 -72 -10 -22 -16 -48 -15 -57 2 -9 4 -24 4 -34 1 -9 16
-34 35 -55 l34 -37 209 -6 c189 -5 213 -7 261 -28 114 -51 168 -154 160 -310
-5 -96 -24 -143 -79 -199 -68 -69 -128 -82 -379 -82 l-189 0 -11 43 c-6 23
-35 128 -65 232 -95 337 -143 508 -175 625 -17 63 -40 145 -51 183 l-20 67
-297 0 c-277 0 -299 -1 -329 -20z"/>
<path d="M2744 2160 c-93 -37 -125 -180 -57 -252 56 -61 139 -74 207 -35 55
31 78 78 74 148 -5 65 -31 109 -82 133 -36 17 -107 20 -142 6z"/>
<path d="M3354 2160 c-33 -13 -82 -77 -90 -115 -19 -104 56 -195 161 -195 169
0 223 228 73 306 -29 15 -111 18 -144 4z"/>

</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
